import type { IData, IRate, IPromo, IPromoGroup } from "~/interfaces";
import { getThemeData } from "~/utils";
const data: IData = getThemeData();

/**
 * Get promo values from json, filtering by current selected credit
 */
export const getPromoValues = (): IPromoGroup | null => {
  let selected: number = getStateValue('credit');
  if (!selected) selected = getStateValue('selectedCredit');

  if (!data.promo?.values || !selected) return null
  
  const promo = data.promo.values.find( value => value.base?.default?.imp_credito === selected)
  return promo ?? null;
}

/**
 * Determine if the selected credit is promo
 */
export const isPromoSelected = (): boolean => {
  return !!getPromoValues() && !!getStateValue('selectedCredit');
}

/**
 * Determine if the selected credit has an upgrade option
 */
export const hasUpgrade = (): boolean => {
  return !!getPromoValues()?.upgrade?.default
}

/**
 * Get promo credit value
 */
export function promoCredit(): number | null {
  return getPromoValues()?.base.default.imp_credito ?? null
}

/**
 * Format promo
 * @param promo
 */
export const formatPromo = (promo: any) => {
  promo.imposta = parseInt(promo.imp_finanz) * 0.0025;
  promo.secci_url = `${data.endpoints.secci}${promo.secci}`

  return formatPriceValues(promo)
}

const getPromoWithServices = (modelGroup: IPromo): IRate => {
  const CLUB = getStateValue("service") && getStateValue("servicePack") === CLUB_PLATINUM.code;
  const INSURANCE_PACKAGE_E = getStateValue("insurance") && getStateValue("insurancePack") === INSURANCE_PACKAGES.E;
  const INSURANCE_PACKAGE_G = getStateValue("insurance") && getStateValue("insurancePack") === INSURANCE_PACKAGES.G;

  let promo : IRate = modelGroup.default;
  if (CLUB) promo = modelGroup.club
  if (INSURANCE_PACKAGE_E) promo = modelGroup.packE;
  if (CLUB && INSURANCE_PACKAGE_E) promo = modelGroup.clubPackE;
  if (INSURANCE_PACKAGE_G) promo = modelGroup.packG;
  if (CLUB && INSURANCE_PACKAGE_G) promo = modelGroup.clubPackG;

  return promo
}

/**
 * Get base promo model
 * @returns IRate | null
 */
export const getPromoModel = (): IRate | null => {
  const promoModels = getPromoValues()?.base;
  if (!promoModels) return null

  return formatPromo(getPromoWithServices(promoModels))
}

/**
 * Get promo upgrade model
 * @returns IRate | null
 */
export const getPromoUpgradeModel = (): IRate | null => {
  const promoValues = getPromoValues();
  const promoModels = promoValues?.base;
  const upgradeModels = promoValues?.upgrade;
  if (!promoModels?.default || !upgradeModels?.default) return null

  const model = getPromoWithServices(upgradeModels)

  const PROMO_TYPE_RATA = promoModels.default.rate != upgradeModels.default.rate && promoModels.default.imp_finanz === upgradeModels.default.imp_finanz
  model.type = PROMO_TYPE_RATA ? 'rata' : 'importo'

  return formatPromo(model);
}

/**
 * Get promoKo model
 */
export const getModelPromoKO = (): IRate | null => {
  const promoModels = getPromoValues()?.base;
  if (!promoModels) return null
  return formatPromo(promoModels.ko)
}

/**
 * Get promo disclaimer message if not promo available
 */
export function promoDisclaimerMessage() {
  return isFeevo() && isPromoSelected() && !promoAvailable();
};

/**
 * Check if promo available
 */
export const promoAvailable = () : boolean => {

  if (!isPromoSelected()) return false

  const employmentType = getStateValue('employmentType');
  const hiringType = getStateValue('hiringType');
  const housingType = getStateValue('housingType');

  const RISK = getStateValue('financialProblems');
  const REDDITO = getStateValue('monthlyIncome', 0);
  const DIPENDENTE_INDETERMINATO = [JOB_TYPES.DIPENDENTE].includes(employmentType) && hiringType == HIRING_TYPES.INDETERMINATO;
  const PROPRIETARIO_CASA = housingType == HOUSING_TYPES.PROPRIETARIO

  return isFeevo() && !RISK && REDDITO >= 2500 && DIPENDENTE_INDETERMINATO && PROPRIETARIO_CASA
}