import { DateTime } from "luxon";

export const EMPLOYMENT_TYPES = {
  AUTONOMO: 1,
  DIPENDENTE: 2,
  STAGIONALE: 3,
  CASALINGA: 4,
  DISOCCUPATO: 5,
  PENSIONATO: 6,
  PENSIONATO_INVALIDO: 7,
  REDDITIERE: 9,
  STUDENTE: 10,
  PROFESSIONISTA: 11,
};

export const HIRING_TYPES = {
  DETERMINATO: 1,
  INDETERMINATO: 2,
};

export const JOB_TYPES = {
  AUTONOMO: 1,
  DIPENDENTE: 2,
  STAGIONALE: 3,
  CASALINGA: 4,
  DISOCCUPATO: 5,
  PENSIONATO: 6,
  PENSIONATO_INVALIDO: 7,
  REDDITIERE: 9,
  STUDENTE: 10,
  PROFESSIONISTA: 11,
};

export const OCCUPATIONS = {
  APPRENDISTA: "APPRENDISTA/INTERINALE/STAGE",
};

export const DOCS = {
  IDENTITY_CARD: 1,
  PASSPORT: 3,
  DRIVERS_LICENSE: 4,
};

export const METLIFE_PRV = [
  "MT", "PZ", "CS", "CZ", "KR", "RC", "VV", "AV", "BN", "CE", "NA", "SA", "CB", "IS", "BA", "BR", "BT", "FG", "LE", "TA", "AG", "CL", "CT", "EN", "ME", "PA", "RG", "SR", "TP",
];

export const INSURANCE_PACKAGES = {
  A: "A",
  E: "E",
  D: "D",
  G: "G",
};

export const INSURANCE_MULTIPLIER = {
  G: 0.00169,
  D: 0.00048,
  E: 0.000613,
};

export const VALID_SECTORS_FOR_INSURANCE_PACKAGE = [1, 2, 3, 5, 7, 10];

export const HOUSING_TYPES = {
  AFFITTO: 1,
  PARENTI: 2,
  PROPRIETARIO: 5
}

export const POWER_CREDIT_RATE = 10
export const POWER_CREDIT_IMP_RATA = 60;
export const POWER_CREDIT_MIN_TAEG = '0,00%';
export const POWER_CREDIT_MAX_TAEG = '21,70%';
export const POWER_CREDIT_MIN_CREDIT = 600;
export const POWER_CREDIT_MAX_CREDIT = 2000;

export const TAX_CODE = {
  birthday: null,
  birthplace: null,
  birthplaceProvincia: null,
  cf: null,
  day: null,
  gender: null,
  month: null,
  name: null,
  surname: null,
  year: null,
  country: 'IT',
  city: null,
}

export const USER_PATHS = {
  CQ: "CQ",
  FE: "FE",
  FAM: "FAM",
  ML: "ML",
};

export const CLUB_PLATINUM = {
  code: "CL390",
  value: 390,
};
export const CLUB_PLUS = {
  code: "CLPLS",
  value: 9.90,
};

export const AGE_LIMIT = 65;

export const REDDITO_LIMIT = 699;

export const RATE_UPGRADE_PATH = "/rata-upgrade";

export const PROMO_CREDIT = 5000;

export const CURRENT_YEAR = new Date().getFullYear();

export const CLUB_PRIVACY_URL = "https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Informativa_Servizio.pdf";

export const CLUB_LIGHT_INFO_URL = "https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Cofidis_Club_LIGHT.pdf";
export const CLUB_LIGHT_TERMS_URL = "https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Regolamento_Servizio_CLUB_LIGHT.pdf";

export const CLUB_PLUS_INFO_URL = "https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Cofidis_Club_PLUS.pdf";
export const CLUB_PLUS_TERMS_URL = "https://cofidis-docs-club.s3.eu-south-1.amazonaws.com/Regolamento_Servizio+CLUB_PLUS.pdf";

export const METLIFE_INFO_URL = "https://d4ybvu6hnmspp.cloudfront.net/metlife-best-care-set-informativo.pdf";
export const METLIFE_PRIVACY_URL = "https://d4ybvu6hnmspp.cloudfront.net/metlife-informativa-privacy.pdf";

export const IBAN_LEN = {
  AT: 20,
  BE: 16,
  BG: 22,
  CH: 21,
  CY: 28,
  CZ: 24,
  DE: 22,
  DK: 18,
  EE: 20,
  ES: 24,
  FI: 18,
  FR: 27,
  GB: 22,
  GI: 23,
  GR: 27,
  HR: 21,
  HU: 28,
  IE: 22,
  IS: 26,
  IT: 27,
  LI: 21,
  LT: 20,
  LU: 20,
  LV: 21,
  MC: 27,
  MT: 31,
  NL: 18,
  NO: 15,
  PL: 28,
  PT: 25,
  RO: 24,
  SE: 24,
  SI: 19,
  SK: 24,
  VA: 22
}
