import type { IData } from "~/interfaces";
import { getThemeData } from "~/utils";

const data: IData = getThemeData()
export default defineNuxtPlugin(() => {
  return {
    provide: {
      login: async (code: string) => {
        const envType = getStateValue('envType')
        const envName = getStateValue('envName')

        await $fetch(data.endpoints.server as string + `access_code/${code}` as string, {
          method: 'POST',
          body: { envType, envName }
        })
      }
    }
  }
})
