export default defineNuxtRouteMiddleware(async (to, from) => {
        const config = useRuntimeConfig();
        const routesPath = config.public.routes;
        try {
                const routesModule = await import(`../themes/${config.public.theme}/routes.ts`);
                const routes = routesModule.default;
                const currentRoute = getRoute(routes, to.path, 'Home');
                const fromRoute = getRoute(routes, from.path, 'Home');

                to.meta = {...to.meta, ...currentRoute.meta};
                from.meta = {...from.meta, ...fromRoute.meta};
                return;
        } catch (error) {
                console.error(`Error importing routes from ${routesPath}:`, error);
        }
});
