import type {IData} from "~/interfaces";
import ClubUserData from "~/mappings/clubUserData";

export default defineNuxtPlugin(() => {
    return {
        provide: {
            subscribeClub: async () => {
                const body = evaluateObjectFunctions(ClubUserData.payload);
                const data: IData = getThemeData()

                await $fetch(data.endpoints.club as string, {
                    method: "POST",
                    body,
                });
            },
        }
    }
});
