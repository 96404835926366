import { getStateValue, getThemeData, updateStateValue } from "../utils";
import { defineNuxtPlugin } from "../.nuxt/imports";
import type { IData } from "~/interfaces";

interface VerifyCrmResponse {
  id: string;
  prod: string;
  success: boolean;
}


const redirects: any = {
  'fast': {
    type: "fast",
    url: "/fs/%d/fast?utm_source=megathron&utm_medium=referral&utm_campaign=creditocofidis"
  },
  'conso': {
    type: "conso",
    url: "/cs/%d/conso?utm_source=megathron&utm_medium=referral&utm_campaign=creditocofidis"
  },
}
const data: IData = getThemeData();

export default defineNuxtPlugin(() => {
  return {
    provide: {
      crmRedirect: async () => {
        try {
          const cf = getStateValue("taxCode");

          const { id, prod, success } = await $fetch<VerifyCrmResponse>(
            `${data.endpoints.crm as string}find/mega_crm_cf`,
            {
              method: "POST",
              body: { cf },
            },
          );

          if (success) {
            const initializeConfig = {
              show : true,
              type : redirects[prod].type,
              redirectUrl : redirects[prod].url.replace("%d", id),
              redirectTimeout : window.setTimeout(() => {
                location.href = `${window.location.origin}${redirects[prod].url.replace("%d", id)}`;
              }, 7000),
              redirectVerified : true
            }

            updateStateValue("crmModal", initializeConfig)
          }
          return success;
        } catch (error) {
          console.error("Error in crmRedirect", error);
          return false;
        }
      },
    },
  };
});
