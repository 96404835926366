import { default as index5v1FpHVTTfMeta } from "/Applications/MAMP/htdocs/credito-nuxt/themes/credito/[[step]]/index.vue?macro=true";
import { default as CreditSwitcherFMwUHloa0vMeta } from "/Applications/MAMP/htdocs/credito-nuxt/themes/credito/components/CreditSwitcher.vue?macro=true";
import { default as FlashMessageAccordionpCD3nwEEHNMeta } from "/Applications/MAMP/htdocs/credito-nuxt/themes/credito/components/FlashMessageAccordion.vue?macro=true";
import { default as HomecQq692lRr8Meta } from "/Applications/MAMP/htdocs/credito-nuxt/themes/credito/components/Home.vue?macro=true";
import { default as RatePowerCreditAoi8k58pNzMeta } from "/Applications/MAMP/htdocs/credito-nuxt/themes/credito/components/RatePowerCredit.vue?macro=true";
import { default as RatePowerCreditUpgradeijcBceGDUKMeta } from "/Applications/MAMP/htdocs/credito-nuxt/themes/credito/components/RatePowerCreditUpgrade.vue?macro=true";
import { default as components9pTd4gzA2GMeta } from "/Applications/MAMP/htdocs/credito-nuxt/themes/credito/config/components.ts?macro=true";
import { default as eventsfiazFEzbMOMeta } from "/Applications/MAMP/htdocs/credito-nuxt/themes/credito/config/events.ts?macro=true";
import { default as handlerssN17yzzdRZMeta } from "/Applications/MAMP/htdocs/credito-nuxt/themes/credito/config/handlers.ts?macro=true";
import { default as mappingJeLO2L1S29Meta } from "/Applications/MAMP/htdocs/credito-nuxt/themes/credito/config/mapping.ts?macro=true";
import { default as props1bCSlPXIu1Meta } from "/Applications/MAMP/htdocs/credito-nuxt/themes/credito/config/props.ts?macro=true";
import { default as debugRX0yiza15dMeta } from "/Applications/MAMP/htdocs/credito-nuxt/themes/credito/data/debug.ts?macro=true";
import { default as devHJg67qPDW7Meta } from "/Applications/MAMP/htdocs/credito-nuxt/themes/credito/data/dev.ts?macro=true";
import { default as ppa4bgqZRSJgMeta } from "/Applications/MAMP/htdocs/credito-nuxt/themes/credito/data/pp.ts?macro=true";
import { default as routesZWsq6Os5D5Meta } from "/Applications/MAMP/htdocs/credito-nuxt/themes/credito/routes.ts?macro=true";
import { default as index1FGZww462bMeta } from "/Applications/MAMP/htdocs/credito-nuxt/themes/credito/utils/index.ts?macro=true";
import { default as prices6suXVm1FBuMeta } from "/Applications/MAMP/htdocs/credito-nuxt/themes/credito/utils/prices.ts?macro=true";
import { default as redirectsPMnAcqp3ehMeta } from "/Applications/MAMP/htdocs/credito-nuxt/themes/credito/utils/redirects.ts?macro=true";
export default [
  {
    name: "step",
    path: "/:step?",
    meta: index5v1FpHVTTfMeta || {},
    component: () => import("/Applications/MAMP/htdocs/credito-nuxt/themes/credito/[[step]]/index.vue")
  },
  {
    name: "components-CreditSwitcher",
    path: "/components/CreditSwitcher",
    component: () => import("/Applications/MAMP/htdocs/credito-nuxt/themes/credito/components/CreditSwitcher.vue")
  },
  {
    name: "components-FlashMessageAccordion",
    path: "/components/FlashMessageAccordion",
    component: () => import("/Applications/MAMP/htdocs/credito-nuxt/themes/credito/components/FlashMessageAccordion.vue")
  },
  {
    name: "components-Home",
    path: "/components/Home",
    component: () => import("/Applications/MAMP/htdocs/credito-nuxt/themes/credito/components/Home.vue")
  },
  {
    name: "components-RatePowerCredit",
    path: "/components/RatePowerCredit",
    component: () => import("/Applications/MAMP/htdocs/credito-nuxt/themes/credito/components/RatePowerCredit.vue")
  },
  {
    name: "components-RatePowerCreditUpgrade",
    path: "/components/RatePowerCreditUpgrade",
    component: () => import("/Applications/MAMP/htdocs/credito-nuxt/themes/credito/components/RatePowerCreditUpgrade.vue")
  },
  {
    name: "config-components",
    path: "/config/components",
    component: () => import("/Applications/MAMP/htdocs/credito-nuxt/themes/credito/config/components.ts")
  },
  {
    name: "config-events",
    path: "/config/events",
    component: () => import("/Applications/MAMP/htdocs/credito-nuxt/themes/credito/config/events.ts")
  },
  {
    name: "config-handlers",
    path: "/config/handlers",
    component: () => import("/Applications/MAMP/htdocs/credito-nuxt/themes/credito/config/handlers.ts")
  },
  {
    name: "config-mapping",
    path: "/config/mapping",
    component: () => import("/Applications/MAMP/htdocs/credito-nuxt/themes/credito/config/mapping.ts")
  },
  {
    name: "config-props",
    path: "/config/props",
    component: () => import("/Applications/MAMP/htdocs/credito-nuxt/themes/credito/config/props.ts")
  },
  {
    name: "data-debug",
    path: "/data/debug",
    component: () => import("/Applications/MAMP/htdocs/credito-nuxt/themes/credito/data/debug.ts")
  },
  {
    name: "data-dev",
    path: "/data/dev",
    component: () => import("/Applications/MAMP/htdocs/credito-nuxt/themes/credito/data/dev.ts")
  },
  {
    name: "data-pp",
    path: "/data/pp",
    component: () => import("/Applications/MAMP/htdocs/credito-nuxt/themes/credito/data/pp.ts")
  },
  {
    name: "routes",
    path: "/routes",
    component: () => import("/Applications/MAMP/htdocs/credito-nuxt/themes/credito/routes.ts")
  },
  {
    name: "utils",
    path: "/utils",
    component: () => import("/Applications/MAMP/htdocs/credito-nuxt/themes/credito/utils/index.ts")
  },
  {
    name: "utils-prices",
    path: "/utils/prices",
    component: () => import("/Applications/MAMP/htdocs/credito-nuxt/themes/credito/utils/prices.ts")
  },
  {
    name: "utils-redirects",
    path: "/utils/redirects",
    component: () => import("/Applications/MAMP/htdocs/credito-nuxt/themes/credito/utils/redirects.ts")
  },
  {
    path: "/auto",
    name: "auto"
  },
  {
    path: "/consolidamento",
    name: "consolidamento"
  }
]