<script setup lang="ts">
const props = defineProps({
  class: { type: String, required: false, default: undefined },
  size: { type: String, required: false, default: "18px" },
});
</script>

<template>
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.class"
    :width="props.size"
    :height="props.size"
    viewBox="0 0 90 90"
    xml:space="preserve"
  >
    <g id="Raggruppa_8306" transform="translate(6749 -856.615)">
      <g id="Raggruppa_1864" transform="translate(-6731.533 879.841)">
        <path
          id="Tracciato_1049"
          class="st0"
          d="M0,13.7c0.1-0.3,0.2-0.6,0.3-0.9c0.6-1.5,2.1-2.5,3.8-2.5c2.7,0,5.4,0,8.2,0
			c5.4,0,10.9,0,16.3,0c0.2,0,0.4,0,0.6,0.1c0.4,0.1,0.6,0.4,0.5,0.8c0,0.4-0.3,0.7-0.7,0.7c-0.2,0-0.4,0-0.6,0H4.4
			C3,11.7,1.8,12.7,1.6,14c0,0.2,0,0.4,0,0.7c0,8.3,0,16.6,0,24.9c-0.2,1.3,0.8,2.6,2.1,2.7c0.2,0,0.4,0,0.6,0h39.5
			c1.3,0.2,2.6-0.8,2.8-2.1c0-0.2,0-0.4,0-0.6c0-4.8,0-9.6,0-14.5c0-0.2,0-0.4,0-0.5c0.1-0.4,0.5-0.7,0.9-0.7
			c0.4,0.1,0.6,0.3,0.7,0.7c0,0.2,0,0.3,0,0.5c0,4.9,0,9.7,0,14.6c0.2,2.2-1.5,4.1-3.7,4.3c-0.2,0-0.4,0-0.6,0
			c-13.2,0-26.3,0-39.5,0c-2.1,0.2-4-1.2-4.4-3.3c0,0,0-0.1-0.1-0.1L0,13.7z"
        />
        <path
          id="Tracciato_1050"
          class="st0"
          d="M4.8,23.9c0-1.5,0-3.1,0-4.6c-0.1-2.2,1.7-4.1,3.9-4.1c0.1,0,0.1,0,0.2,0
			c3.1,0,6.2,0,9.3,0c2.2-0.1,4,1.6,4.1,3.8c0,0.1,0,0.1,0,0.2c0,3.2,0,6.4,0,9.5c0,2.2-1.8,4-4,4c0,0,0,0,0,0c-3.1,0-6.2,0-9.3,0
			c-2.2,0.1-4.1-1.6-4.2-3.9c0-0.1,0-0.2,0-0.3C4.8,27,4.8,25.4,4.8,23.9z M19.8,30.6c0.6-0.4,1-1.1,1-1.9c0-3.2,0-6.4,0-9.6
			c0-1.3-1-2.3-2.3-2.4c0,0,0,0-0.1,0c-3.2,0-6.5,0-9.7,0c-1.3,0-2.3,1-2.3,2.3c0,3.2,0,6.5,0,9.7c0,0.7,0.4,1.4,0.9,1.8
			c0.6-1.7,1.8-3.1,3.4-3.9c-2-1.6-2.4-4.6-0.7-6.7c0.1-0.1,0.2-0.2,0.3-0.4c1.8-1.8,4.6-1.8,6.5-0.1c0.9,0.8,1.4,1.9,1.5,3.1
			c0.1,1.6-0.6,3.1-1.8,4C18.5,28.1,19,28.6,19.8,30.6L19.8,30.6z M10.5,23c0,1.7,1.4,3,3.1,3c1.7,0,3-1.4,3-3.1c0-1.7-1.4-3-3.1-3
			C11.9,20,10.5,21.3,10.5,23L10.5,23z M18.2,31.1c-0.3-1.9-2.6-3.5-4.8-3.4c-2,0.1-4.2,1.8-4.4,3.4H18.2z"
        />
        <path
          id="Tracciato_1051"
          class="st0"
          d="M33.5,18.3c-0.3-0.5-0.5-1-0.8-1.4c-0.1-0.1-0.3-0.2-0.5-0.2c-1.7,0-3.5,0-5.2,0
			c-0.1,0-0.3,0-0.4,0c-0.4,0-0.8-0.3-0.9-0.7c0-0.4,0.3-0.8,0.7-0.9c0,0,0.1,0,0.1,0c1.1,0,2.2,0,3.3,0h2.1
			c-0.1-0.9-0.3-1.8-0.4-2.8C31,6,35.9,0.5,42.3,0.1c6.4-0.4,11.9,4.4,12.3,10.8c0.4,6.4-4.4,11.9-10.8,12.3c-3.1,0.2-6.2-0.9-8.5-3
			c-0.2-0.2-0.5-0.2-0.7-0.2c-2.6,0-5.2,0-7.8,0c-0.3,0-0.6-0.1-0.9-0.4c-0.3-0.3-0.2-0.8,0.2-1.1c0.1-0.1,0.3-0.2,0.5-0.2
			c0.5,0,1,0,1.5,0H33.5z M53,11.6c0-5.5-4.5-10-10-10c-5.5,0-10,4.5-10,10c0,5.5,4.5,10,10,10C48.5,21.6,53,17.1,53,11.6L53,11.6z"
        />
        <path
          id="Tracciato_1052"
          class="st0"
          d="M24.1,34.3h18c0.2,0,0.4,0,0.5,0c0.4,0,0.8,0.4,0.7,0.8c0,0.4-0.3,0.7-0.6,0.7
			c-0.2,0-0.4,0-0.5,0c-12.1,0-24.2,0-36.3,0c-0.2,0-0.4,0-0.5,0c-0.4-0.1-0.7-0.5-0.6-0.9c0.1-0.3,0.4-0.6,0.7-0.6
			c0.2,0,0.4,0,0.5,0H24.1z"
        />
        <path
          id="Tracciato_1053"
          class="st0"
          d="M24.1,37.5h18c0.2,0,0.4,0,0.5,0c0.4,0,0.8,0.4,0.7,0.8c0,0.4-0.3,0.7-0.6,0.7
			c-0.2,0-0.4,0-0.5,0c-12.1,0-24.2,0-36.3,0c-0.2,0-0.4,0-0.5,0C5,39,4.7,38.6,4.8,38.1c0.1-0.3,0.4-0.6,0.7-0.6c0.2,0,0.4,0,0.5,0
			H24.1z"
        />
        <path
          id="Tracciato_1054"
          class="st0"
          d="M34.5,27.9c2.6,0,5.2,0,7.8,0c0.3,0,0.6,0.1,0.8,0.3c0.3,0.3,0.3,0.8-0.1,1
			c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,0-0.4,0.1-0.6,0.1c-5.1,0-10.3,0-15.4,0c-0.1,0-0.2,0-0.3,0c-0.6,0-0.9-0.3-0.9-0.8
			s0.4-0.8,0.9-0.8C29.2,27.9,31.9,27.9,34.5,27.9z"
        />
        <path
          id="Tracciato_1055"
          class="st0"
          d="M34.5,31.1c2.6,0,5.3,0,7.9,0c0.3,0,0.6,0.1,0.8,0.3c0.3,0.3,0.3,0.8,0,1
			c-0.1,0.1-0.2,0.2-0.3,0.2c-0.2,0.1-0.4,0.1-0.6,0.1c-5.2,0-10.3,0-15.5,0c-0.1,0-0.2,0-0.3,0c-0.5,0-0.8-0.3-0.9-0.8c0,0,0,0,0,0
			c0-0.4,0.4-0.8,0.8-0.8c0,0,0.1,0,0.1,0c2.3,0,4.7,0,7,0L34.5,31.1z"
        />
        <path
          id="Tracciato_1056"
          class="st0"
          d="M41.3,16.1c-0.4-0.2-0.8-0.4-1.2-0.7c-0.7-0.6-1.4-1.4-2.1-2.1c-0.3-0.3-0.4-0.8-0.1-1.1
			c0.3-0.3,0.8-0.4,1.1-0.1c0,0,0.1,0.1,0.1,0.1c0.6,0.6,1.2,1.2,1.8,1.8c0.3,0.3,0.5,0.3,0.8,0c1.7-1.7,3.3-3.3,5-5
			c0.1-0.1,0.2-0.3,0.4-0.4c0.4-0.3,0.8-0.2,1.1,0.2c0.2,0.3,0.2,0.6,0,0.9c-0.1,0.1-0.2,0.2-0.3,0.3c-1.7,1.7-3.5,3.5-5.2,5.2
			C42.3,15.7,41.8,16,41.3,16.1z"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped></style>
