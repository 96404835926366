<script setup lang="ts">
const props = defineProps({
  class: { type: String, required: false, default: undefined },
  size: { type: String, required: false, default: "18px" },
});
</script>

<template>
  <!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.class"
    :width="size"
    :height="size"
    viewBox="0 0 60 60"
    style="enable-background: new 0 0 60 60"
    xml:space="preserve"
  >
    <g id="Raggruppa_8592" transform="translate(-5143 -5397)">
      <g id="Raggruppa_8556" transform="translate(5164.166 5402.315)">
        <path
          id="Tracciato_7977"
          class="st0"
          d="M16.9,48.9c-0.2-0.2-0.4-0.4-0.3-0.7c0-1.9,0-3.9,0.1-5.8c0-0.7-0.3-1.1-1-1.1
			c-1.3,0-2.6,0-3.9-0.1c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.1-0.3-0.1-0.5c0.1-0.2,0.3-0.3,0.5-0.3c0.8,0,1.7,0,2.5,0h0.4
			c0-0.1,0-0.3,0-0.4c0-1.6,0-3.1,0-4.7c0-0.3-0.1-0.6-0.3-0.8c-2.1-2.2-4.2-4.3-6.3-6.5c0,0-0.1-0.1-0.2-0.1
			c-0.2,0.3-0.3,0.7-0.4,1c-0.2,0.6,0,1.3,0.5,1.7c1.1,1.1,2.2,2.3,3.3,3.4c0.1,0.1,0.1,0.1,0.2,0.2c0.2,0.2,0.2,0.5,0,0.7
			c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.2,0.1c-0.4,0.1-0.5-0.1-0.7-0.3c-1.9-2-3.9-4-5.8-5.9C4.2,28.2,4,27.7,4,27.2
			c0-2.2,0.1-4.5,0.1-6.7c0-0.9-0.6-1.6-1.5-1.6c0,0,0,0,0,0v0.4c0,3.6-0.1,7.2-0.1,10.8c0,0.3,0.1,0.6,0.2,0.8c1.8,3,3.5,6,5.3,9
			c0.1,0.2,0.3,0.3,0.5,0.3c0.2,0,0.4,0,0.6,0c0.3,0,0.5,0.2,0.5,0.5c0,0,0,0,0,0.1c0,0.3-0.2,0.5-0.5,0.5c0,0,0,0-0.1,0
			c-0.6,0-1.1,0-1.7,0c-0.5-0.1-1,0.3-1,0.7c0,0.1,0,0.2,0,0.3c0,2,0,3.9-0.1,5.8c0,0.3-0.1,0.6-0.3,0.8H5.6
			c-0.2-0.1-0.4-0.3-0.4-0.6c0-2.1,0-4.2,0.1-6.3c0-0.8,0.6-1.5,1.3-1.7c0.1,0,0.2-0.1,0.4-0.1c-0.1-0.1-0.1-0.3-0.2-0.4
			c-1.5-2.6-3-5.1-4.6-7.7c-0.3-0.5-0.5-1-0.7-1.4c0-4.1,0.1-8.1,0.1-12.2c0.3-0.5,0.8-0.7,1.4-0.5c1.3,0.2,2.3,1.3,2.2,2.7
			c0,0.1,0,0.3,0,0.5c0.8,0,1.5,0.4,2,1c0.4,0.6,0.6,1.3,0.6,2c0.5,0.1,0.9,0.3,1.4,0.5c0.7,0.4,1.2,1.3,1.2,2.1c0,0.5,0,1.1,0,1.6
			c0,0.2,0.1,0.4,0.2,0.5c1.5,1.5,3,3.1,4.5,4.6c0.5,0.5,0.7,1.1,0.7,1.7c0,1.5,0,3-0.1,4.5v0.4c0.2,0,0.4,0.1,0.6,0.1
			c0.9,0.2,1.5,1,1.5,1.9c0,2.1,0,4.1-0.1,6.2c0,0.3-0.1,0.5-0.3,0.6L16.9,48.9z M5.2,22c0,0,0,0.1,0,0.1c0,1.7,0,3.5-0.1,5.2
			c0,0.1,0,0.2,0.1,0.3c0.4,0.4,0.7,0.7,1.1,1.1c0.1-0.3,0.3-0.7,0.3-1c0-1.4,0.1-2.8,0-4.2C6.6,22.7,6,22,5.2,22L5.2,22z M9.2,27.5
			c0.1-1.5-0.4-2.3-1.5-2.3l0,1.3C8.4,26.5,8.6,27.2,9.2,27.5L9.2,27.5z"
        />
        <path
          id="Tracciato_7978"
          class="st1"
          d="M0.7,0.1c0.3,0.2,0.4,0.4,0.4,0.7c0,2,0,3.9,0.1,5.8c0,0.7,0.3,1.1,1.1,1.1
			c1.3,0,2.6,0,3.9,0c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.1,0.3,0.1,0.5C6.6,8.6,6.4,8.7,6.2,8.7c-0.8,0-1.7,0-2.5,0H3.2
			c0,0.1,0,0.3,0,0.4c0,1.6,0,3.1,0.1,4.7c0,0.3,0.1,0.6,0.3,0.8c2.1,2.1,4.3,4.2,6.4,6.3c0,0,0.1,0.1,0.2,0.1
			c0.2-0.3,0.3-0.7,0.4-1c0.2-0.6,0-1.3-0.5-1.7C9,17.3,7.9,16.2,6.7,15c-0.1-0.1-0.2-0.1-0.2-0.2c-0.2-0.2-0.2-0.5,0-0.7
			c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.1c0.4-0.1,0.5,0.1,0.7,0.3c2,1.9,4,3.9,5.9,5.8c0.4,0.4,0.6,0.9,0.6,1.4c0,2.2,0,4.5,0.1,6.7
			c0,0.9,0.6,1.6,1.5,1.6c0,0,0,0,0,0v-0.4c0-3.6-0.1-7.2-0.1-10.8c0-0.3-0.1-0.6-0.2-0.8c-1.8-3-3.7-5.9-5.5-8.9
			C9.7,8.8,9.5,8.7,9.3,8.7c-0.2,0-0.4,0-0.6,0c-0.3,0-0.5-0.2-0.6-0.5c0,0,0,0,0-0.1c0-0.3,0.2-0.5,0.5-0.5c0,0,0,0,0.1,0
			c0.6,0,1.1,0,1.7,0c0.5,0.1,0.9-0.3,1-0.8c0-0.1,0-0.2,0-0.3c0-1.9,0-3.9-0.1-5.8c0-0.3,0.1-0.6,0.3-0.8L12,0
			c0.2,0.1,0.4,0.3,0.4,0.6c0,2.1,0,4.2,0.1,6.3c0,0.8-0.5,1.5-1.3,1.8c-0.1,0-0.2,0.1-0.4,0.1C10.9,8.9,11,9,11.1,9.1
			c1.6,2.5,3.1,5.1,4.7,7.6c0.3,0.5,0.5,0.9,0.8,1.4c0,4.1,0.1,8.1,0.1,12.2c-0.3,0.5-0.8,0.7-1.4,0.5C14,30.7,13,29.6,13,28.3
			c0-0.1,0-0.3,0-0.5c-0.8,0-1.5-0.4-2-1c-0.5-0.6-0.7-1.3-0.6-2c-0.5-0.1-0.9-0.3-1.4-0.5c-0.8-0.4-1.2-1.2-1.2-2.1
			c0-0.5,0-1.1,0-1.6c0-0.2-0.1-0.4-0.2-0.5C6,18.5,4.5,17,3,15.5c-0.5-0.4-0.8-1.1-0.7-1.7c0-1.5,0-3,0-4.5V8.8
			c-0.2,0-0.4,0-0.6-0.1C0.7,8.6,0.1,7.8,0.1,6.9C0,4.9,0,2.8,0,0.7c0-0.2,0.1-0.5,0.3-0.6L0.7,0.1z M13,26.7c0,0,0-0.1,0-0.1
			c0-1.7,0-3.5-0.1-5.2c0-0.1,0-0.2-0.1-0.3c-0.4-0.4-0.8-0.7-1.1-1.1c-0.1,0.3-0.2,0.7-0.3,1.1c0,1.4,0,2.8,0,4.2
			C11.5,26.1,12.2,26.7,13,26.7L13,26.7z M8.9,21.3c-0.1,1.5,0.5,2.3,1.5,2.3l0-1.3C9.7,22.3,9.4,21.6,8.9,21.3L8.9,21.3z"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped>
.st0 {
  fill: #363636;
}
.st1 {
  opacity: 0.3;
  fill: #363636;
}
</style>
