export default defineNuxtPlugin(() => {
  return {
    provide: {
      gtm: (title: string, data = {}) => {
        const { dataLayer } = useScriptGoogleTagManager()
        dataLayer.push({event: 'event', title, data})
      }
    },
  };
});
