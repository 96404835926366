<script setup lang="ts">
const props = defineProps({
  class: { type: String, required: false, default: undefined },
  size: { type: String, required: false, default: "18px" },
});
</script>

<template>
  <!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    :class="props.class"
    :width="size"
    :height="size"
    viewBox="0 0 60 60"
    style="enable-background: new 0 0 60 60"
    xml:space="preserve"
  >
    <g id="Raggruppa_8588" transform="translate(-5260.502 -5396.294)">
      <g id="Raggruppa_8559" transform="translate(5269.095 5402.989)">
        <g id="Raggruppa_8557" transform="translate(16.512 0)">
          <path
            id="Tracciato_7979"
            class="st0"
            d="M10.2,2.8v0.4c0,0.1,0,0.2-0.1,0.3C10,4.1,9.7,4.6,9.3,5C8,6.5,6.7,7.9,5.3,9.4
				C5.2,9.5,5.1,9.6,4.9,9.5c0,0-0.1-0.1-0.1-0.1C3.6,8.1,2.4,6.8,1.3,5.5C1,5.2,0.8,4.9,0.5,4.6c-0.9-1.3-0.6-3,0.6-4
				c1-0.8,2.4-0.9,3.4-0.2c0.2,0.1,0.3,0.2,0.2,0.4c0,0,0,0.1-0.1,0.1l-1,1.6c0,0,0,0.1-0.1,0.1c0,0,0.1,0,0.1,0.1l1.5,1.5
				c0.2,0.2,0.2,0.3,0,0.6C4.9,5.1,4.6,5.4,4.3,5.7c0,0-0.1,0.1,0,0.1c0.2,0.5,0.3,1,0.5,1.5c0,0,0,0,0,0.1c0-0.3,0-0.7,0-1
				c0-0.1,0-0.2,0.1-0.3c0.5-0.5,1-1,1.4-1.4c0,0,0-0.1,0.1-0.1c0,0-0.1-0.1-0.1-0.1L4.9,2.9C4.8,2.8,4.7,2.6,4.8,2.5c0,0,0,0,0-0.1
				C5.5,1.7,6.1,1,6.7,0.2C6.8,0.1,6.9,0,7.1,0c0.6,0,1.2,0.2,1.7,0.5c0.6,0.4,1.1,1,1.2,1.7C10.1,2.4,10.1,2.6,10.2,2.8z"
          />
        </g>
        <g id="Raggruppa_8558" transform="translate(0 8.958)">
          <path
            id="Tracciato_7980"
            class="st0"
            d="M42.8,0c0.3,0.2,0.4,0.4,0.4,0.8c0,2.5,0,5,0,7.5c0,1.1-0.8,2.1-1.9,2.3
				c-0.2,0-0.4,0.1-0.7,0.1v0.5c0,1.8,0,3.7,0,5.5c0,0.8-0.3,1.6-0.9,2.1c-1.9,1.8-3.7,3.7-5.6,5.6c-0.2,0.2-0.3,0.4-0.3,0.7
				c0,0.7,0,1.3,0,2c0,1.1-0.6,2-1.5,2.6c-0.5,0.3-1.1,0.5-1.7,0.7c0.1,0.9-0.2,1.8-0.7,2.5c-0.6,0.7-1.5,1.2-2.5,1.2
				c0,0.2,0,0.4,0,0.6c0,1.6-1.2,3-2.8,3.2c-0.6,0.2-1.3-0.1-1.7-0.7V22.2c0.3-0.6,0.6-1.2,0.9-1.7c1.9-3.1,3.8-6.3,5.7-9.4
				c0.1-0.1,0.2-0.3,0.3-0.5c-0.2-0.1-0.3-0.1-0.4-0.1C28.6,10.2,28,9.3,28,8.4c0-2.6,0-5.1,0-7.7c0-0.3,0.2-0.6,0.4-0.7h0.4
				c0.3,0.2,0.5,0.6,0.4,1c0,2.4,0,4.8,0,7.2c-0.1,0.6,0.3,1.2,0.9,1.2c0.1,0,0.2,0,0.3,0c0.7,0,1.4,0,2.1,0c0.3,0,0.6,0.2,0.7,0.5
				c0,0,0,0,0,0.1c0,0.4-0.3,0.6-0.6,0.7c0,0,0,0-0.1,0c-0.2,0-0.5,0-0.7,0c-0.3,0-0.5,0.1-0.6,0.4c-2.2,3.6-4.4,7.3-6.6,10.9
				c-0.2,0.3-0.3,0.7-0.3,1c0,4.4,0,8.8,0,13.2v0.4c1,0,1.9-0.9,1.9-1.9c0,0,0,0,0-0.1c0-2.7,0-5.5,0-8.2c0-0.6,0.2-1.3,0.7-1.7
				c2.4-2.4,4.8-4.8,7.2-7.2c0.2-0.2,0.4-0.5,0.8-0.4c0.1,0,0.2,0.1,0.2,0.1c0.3,0.2,0.3,0.6,0.1,0.8c0,0,0,0,0,0
				c-0.1,0.1-0.2,0.2-0.3,0.3c-1.4,1.4-2.7,2.7-4.1,4.1c-0.6,0.5-0.8,1.3-0.6,2.1c0.1,0.4,0.3,0.8,0.5,1.2c0.1-0.1,0.2-0.1,0.2-0.2
				c2.6-2.6,5.2-5.2,7.8-7.8c0.2-0.3,0.4-0.6,0.4-1c0-1.9,0-3.8,0-5.7v-0.5h-0.5c-1,0-2.1,0-3.1,0c-0.3,0-0.5-0.2-0.6-0.4
				C35,10,35,9.8,35.2,9.6c0.2-0.2,0.4-0.3,0.6-0.3c1.6,0,3.2,0,4.8,0c0.9,0,1.3-0.4,1.3-1.3c0-2.4,0-4.8,0-7.2
				c0-0.4,0.1-0.7,0.4-0.9L42.8,0z M29.4,30.9c0-1.7,0-3.4,0-5.2c-0.1-0.4-0.2-0.9-0.4-1.3c-0.5,0.4-0.9,0.9-1.4,1.3
				c-0.1,0.1-0.1,0.3-0.1,0.4c0,2.1,0,4.2,0,6.3c0,0.1,0,0.1,0,0.2C28.6,32.7,29.4,31.9,29.4,30.9L29.4,30.9z M30.7,27.3v1.6
				c1.3,0,1.9-1,1.8-2.8C31.9,26.4,31.6,27.3,30.7,27.3L30.7,27.3z"
          />
          <path
            id="Tracciato_7981"
            class="st0"
            d="M0.9,0c0.3,0.2,0.5,0.5,0.4,0.9c0,2.4,0,4.8,0,7.2c0,0.9,0.4,1.3,1.3,1.3
				c1.6,0,3.2,0,4.8,0c0.2,0,0.5,0.1,0.6,0.3c0.1,0.2,0.2,0.4,0.1,0.6c-0.2,0.2-0.4,0.3-0.6,0.4c-1,0-2.1,0-3.1,0H3.8v0.5
				c0,1.9,0,3.8,0,5.7c0,0.4,0.1,0.7,0.4,1c2.6,2.6,5.2,5.2,7.8,7.8c0,0,0.1,0.1,0.2,0.2c0.2-0.4,0.4-0.8,0.5-1.2
				c0.2-0.8,0-1.6-0.6-2.1c-1.4-1.4-2.7-2.7-4.1-4.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.2-0.2-0.6,0.1-0.8c0,0,0,0,0,0
				C8,17.1,8,17.1,8.1,17.1C8.6,17,8.8,17.2,9,17.4c2.4,2.4,4.8,4.8,7.2,7.2c0.5,0.4,0.7,1.1,0.7,1.7c0,2.7,0,5.5,0,8.2
				c0,1,0.8,1.9,1.8,2c0,0,0,0,0.1,0v-0.4c0-4.4,0-8.8,0-13.2c0-0.4-0.1-0.7-0.3-1c-2.2-3.6-4.4-7.3-6.6-10.9
				c-0.1-0.3-0.4-0.4-0.6-0.4c-0.2,0-0.5,0-0.7,0c-0.4,0-0.7-0.2-0.7-0.6c0,0,0,0,0-0.1c0-0.3,0.3-0.6,0.6-0.6c0,0,0,0,0.1,0
				c0.7,0,1.4,0,2.1,0c0.6,0.1,1.2-0.3,1.3-0.9c0-0.1,0-0.2,0-0.3c0-2.4,0-4.8,0-7.2c0-0.4,0.1-0.7,0.4-1h0.4
				c0.3,0.1,0.4,0.4,0.4,0.7c0,2.6,0,5.1,0,7.7c0,1-0.7,1.8-1.6,2.1c-0.1,0-0.3,0.1-0.4,0.1c0.1,0.2,0.2,0.3,0.3,0.5
				c1.9,3.1,3.8,6.3,5.7,9.4c0.3,0.6,0.6,1.2,0.9,1.7v14.9c-0.3,0.6-1,0.8-1.7,0.7c-1.6-0.2-2.8-1.6-2.8-3.2c0-0.2,0-0.4,0-0.6
				c-1,0-1.8-0.5-2.5-1.2c-0.5-0.7-0.8-1.6-0.7-2.5c-0.6-0.2-1.1-0.4-1.7-0.7c-0.9-0.5-1.5-1.5-1.5-2.6c0-0.7,0-1.3,0-2
				c0-0.3-0.1-0.5-0.3-0.7c-1.9-1.9-3.7-3.7-5.6-5.6c-0.6-0.5-0.9-1.3-0.9-2.1c0-1.8,0-3.7,0-5.5v-0.5c-0.2,0-0.5-0.1-0.7-0.1
				C0.8,10.4,0,9.4,0,8.3c0-2.5,0-5,0-7.5C0,0.5,0.1,0.2,0.4,0L0.9,0z M15.6,32.7c0-0.1,0-0.1,0-0.2c0-2.1,0-4.2,0-6.3
				c0-0.1,0-0.3-0.1-0.4c-0.4-0.4-0.9-0.9-1.4-1.3c-0.2,0.4-0.3,0.8-0.4,1.3c0,1.7,0,3.4,0,5.2C13.8,31.9,14.6,32.7,15.6,32.7
				L15.6,32.7z M10.6,26c-0.1,1.8,0.5,2.8,1.8,2.8v-1.6C11.5,27.3,11.2,26.4,10.6,26L10.6,26z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<style scoped>
.st0 {
  fill: #363636;
}
</style>
