import Inview from "inview";

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('inview', {
        created: (el: HTMLElement) => { //Initial hide element for smooth transition
            el.style.opacity = "0";
        },
        mounted: (el, binding) => {
            const {enter: onlyEnter = false, out: onlyOut = false} = binding.modifiers;

            const toggleClass = (isVisible: Boolean) => {
                const value = binding.value.split(" ");

                if (isVisible && !onlyOut) {
                    el.classList.add(...value);
                } else if (!isVisible && !onlyEnter) {
                    el.classList.remove(...value);
                }

                el.style.opacity = "100"
            }

            setTimeout(() => {
                Inview(el, (isVisible: boolean) => toggleClass(isVisible));
            }, 600)
        }
    });
});
